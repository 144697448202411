import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from '../components/PageTitle'
import SEO from '../components/seo'

import useStyles from '../styles/generalStyles'

const Philosophy = () => {
  const classes = useStyles()
  const query = useStaticQuery(graphql`
{
    allMarkdownRemark(filter: {frontmatter: {id: {eq: "philosophy"}}}) {
      nodes {
        frontmatter {
          title
          seoDescription
          seoKeywords
          seoTitle
          imageAlt
          imageTitle
          teamImage {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
        html
      }
    }
  }
`)

  const pageTitle = query.allMarkdownRemark.nodes[0].frontmatter.title
  const teamImage = query.allMarkdownRemark.nodes[0].frontmatter.teamImage.childImageSharp.fluid.originalImg
  const seoDescription = query.allMarkdownRemark.nodes[0].frontmatter.seoDescription
  const seoKeywords = query.allMarkdownRemark.nodes[0].frontmatter.seoKeywords
  const seoTitle = query.allMarkdownRemark.nodes[0].frontmatter.seoTitle
  const imageTitle = query.allMarkdownRemark.nodes[0].frontmatter.imageTitle
  const imageAlt = query.allMarkdownRemark.nodes[0].frontmatter.imageAlt

  return (
    <Layout>
      <SEO title={seoTitle !== undefined ? seoTitle : pageTitle} description={seoDescription !== undefined ? seoDescription : null} keywords={seoKeywords !== undefined ? seoKeywords : null}/>
      <PageTitle title={pageTitle} />
      <div className={classes.main} dangerouslySetInnerHTML={{ __html: query.allMarkdownRemark.nodes[0].html }} />
      <img className={classes.image} alt={imageAlt} title={imageTitle} src={teamImage}/>
    </Layout>
  )
}
export default Philosophy
